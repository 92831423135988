import React, { Fragment } from "react";
import { compose } from "recompose";

import Layout from "../components/layout";
import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../components/Account/Session";
import SignOutButton from "../components/Account/SignOut";
// import PasswordForgetForm from "../components/Account/PasswordForget";
import PasswordChangeForm from "../components/Account/PasswordChange";
// import LoginManagement from "../components/Account/LoginManagement";

const AccountPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>
      {(authUser) => (
        <div>
          <h1>Account</h1>
          {authUser.username && (
            <p>
              <b>Naam: </b>
              {authUser.username}
            </p>
          )}
          <p>
            <b>Email: </b>
            {authUser.email}
          </p>
          <SignOutButton />
          {/* <PasswordForgetForm /> */}
          <PasswordChangeForm />
          {/* <LoginManagement authUser={authUser} /> */}
        </div>
      )}
    </AuthUserContext.Consumer>
  </Fragment>
);

const condition = (authUser) => !!authUser;

const AccountPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AccountPageBase);

export default ({ location }) => (
  <Layout location={location}>
    <AccountPage />
  </Layout>
);
